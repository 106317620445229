import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade anything. No registration, no hassle.'),
  bodyText: t('Trade any token on BNB Smart Chain in seconds, just by connecting your wallet.'),
  reverse: true,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: 'BNB', alt: t('BNB token') },
      { src: 'BTC', alt: t('BTC token') },
      { src: 'CAKE', alt: t('CAKE token') },
    ],
  },
})

export const shownotesSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Unveiling ShowNotes: The Elegant AI-Powered Crypto Stream Digest'),
  bodyText: t("Prepare to be enchanted by ShowNotes, an AI-driven Crypto Stream Digest that seamlessly curates ticker-by-ticker summaries from the vibrant crypto markets. Experience the time-saving pleasure of accessing crucial insights extracted from trader Twitch and YouTube streams. With ShowNotes, embrace the harmony of elegance and efficiency as you gracefully navigate the dynamic realm of crypto markets."),
  reverse: false,
  primaryButton: {
    to: '/leaderboard',
    text: t('Discover ShowNotes Now'),
    external: false,
  },
  secondaryButton: {
    to: '/faqs/leaderboard',
    text: t(''),
    external: false,
  },
  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'shownotes', alt: t('') },

    ],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Introducing COPE Chat'),
  bodyText: t(
    'Engage in fascinating conversations with your beloved Trader AIs and uncover the brilliance behind their thoughts. Immerse yourself in an elegant and stimulating environment where wit and wisdom are served with a dash of fun. Welcome to a world of concise yet captivating AI interactions, tailored to enthrall every trading aficionado. Delve into the extraordinary and unravel the secrets of the trading realm with AI Trader Chats!',
  ),
  reverse: true,
  primaryButton: {
    to: '/chat',
    text: t('COPE Chat'),
    external: false,
  },
  secondaryButton: {
    to: '/chat/faqs',
    text: t(''),
    external: true,
  },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'bottom-right', alt: t('Small 3d pancake') },
      { src: 'top-right', alt: t('Small 3d pancake') },
      { src: 'chat', alt: t('CAKE token') },
      { src: 'top-left', alt: t('Small 3d pancake') },
    ],
  },
})
