import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade anything. No registration, no hassle.'),
  bodyText: t('Trade any token on BNB Smart Chain in seconds, just by connecting your wallet.'),
  reverse: true,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.pancakeswap.finance/',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: 'BNB', alt: t('BNB token') },
      { src: 'BTC', alt: t('BTC token') },
      { src: 'CAKE', alt: t('CAKE token') },
    ],
  },
})

export const cardSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Explore the Artistry of Generative Trader Profiles'),
  bodyText: t('Dive into the captivating world of Generative Trader Profiles, where each artistic profile card is skillfully crafted based on traders market predictions records. Uncover the unique patterns and insights of their trading prowess through this innovative fusion of art and analytics.'),
  reverse: false,
  primaryButton: {
    to: '/leaderboard',
    text: t('Explore'),
    external: false,
  },
  secondaryButton: {
    to: '/faqs/leaderboard',
    text: t(''),
    external: false,
  },
  images: {
    path: '/images/home/earn/',
    attributes: [
      { src: 'pie', alt: t('Pie chart') },
      { src: 'stonks', alt: t('Stocks chart') },
      { src: 'folder', alt: t('Folder with cake token') },
    ],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Introducing COPE Chat'),
  bodyText: t(
    'Engage in fascinating conversations with your beloved Trader AIs and uncover the brilliance behind their thoughts. Immerse yourself in an elegant and stimulating environment where wit and wisdom are served with a dash of fun. Welcome to a world of concise yet captivating AI interactions, tailored to enthrall every trading aficionado. Delve into the extraordinary and unravel the secrets of the trading realm with AI Trader Chats!',
  ),
  reverse: true,
  primaryButton: {
    to: '/chat',
    text: t('COPE Chat'),
    external: false,
  },
  secondaryButton: {
    to: '/chat/faqs',
    text: t(''),
    external: true,
  },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'bottom-right', alt: t('Small 3d pancake') },
      { src: 'top-right', alt: t('Small 3d pancake') },
      { src: 'chat', alt: t('CAKE token') },
      { src: 'top-left', alt: t('Small 3d pancake') },
    ],
  },
})
